import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { adminResetPassword } from "../actions/admin";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

const ResetNewPassword = () => {
  const location = useLocation();

  const [newpwd, setNewpwd] = useState(false);
  const [confirmpwd, setConfirmpwd] = useState(false);

  const [formvalue, setFormvalue] = useState({});
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);
      setErrors(validation(Formvalue));
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const validation = (value) => {
    try {
      let error = errors;
      if (isEmpty(value.password)) {
        error["password"] = "Field is required";
      } else {
        error["password"] = "";
      }
      if (isEmpty(value.confirmPassword)) {
        error["confirmPassword"] = "Field is required";
      } else if (value.confirmPassword != value.password) {
        error["confirmPassword"] =
          "Confrim password must match with new password";
      } else {
        error["confirmPassword"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      let userData = {
        password: formvalue.password,
        email: location.state.datas,
        confirmPassword: formvalue.confirmPassword,
      };
      let isValidate = validation(userData);
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let { status, message, result } = await adminResetPassword(userData);
      if (status == "success") {
        toast.success("Password Changed Successfully");
        history.push("/");
      } else {
        toast.success("Password Changed Failed");
        history.push("/forgotpassword");
      }
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  return (
    <>
      <Container fluid className="common_bg login_bg position-relative">
        <Row className="justify-content-center align-items-center row_card">
          <Col xl={6} lg={6} md={8} sm={12}>
            <div className="dashboard_box p-4">
              <div className="logo_sec d-flex justify-content-center">
                <img
                  src={require("../assets/images/logo.svg").default}
                  className="img-fluid main_logo"
                />
              </div>
              <div className="profile_holder mt-3">
                <p className="header_title_big">Reset Password</p>
                <hr className="grey_hr" />
              </div>

              <div className="profile_holder">
                <Row>
                  <Col lg={12} md={12} sm={12} className="mb-4">
                    <div className="rp_singleinput_holder">
                      <p className="rp_label mb-2">New Password</p>
                      <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                        <input
                          type={newpwd ? "text" : "password"}
                          placeholder="Enter Password"
                          className="rp_singleInput flex-grow-1"
                          onChange={handleChange}
                          name="password"
                        />

                        <i
                          class={
                            newpwd ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"
                          }
                          onClick={() => setNewpwd(!newpwd)}
                        />
                      </div>
                      <span>{errors.password}</span>
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12} className="mb-4">
                    <div className="rp_singleinput_holder">
                      <p className="rp_label mb-2">Confirm Password</p>
                      <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                        <input
                          type={confirmpwd ? "text" : "password"}
                          placeholder="Enter Password"
                          className="rp_singleInput flex-grow-1"
                          name="confirmPassword"
                          onChange={handleChange}
                        />

                        <i
                          class={
                            confirmpwd
                              ? "fa-solid fa-eye-slash"
                              : "fa-solid fa-eye"
                          }
                          onClick={() => setConfirmpwd(!confirmpwd)}
                        />
                      </div>
                      <span>{errors.confirmPassword}</span>
                    </div>
                    <div className="mt-3 text-end">
                      <Link to="/" className="link_theme">
                        Back to Login?
                      </Link>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  {/* <NavLink className="sidebar_links" to="/"> */}
                  <button
                    className="orange_small_primary"
                    onClick={() => {
                      handleSubmit();
                    }}>
                    Submit
                  </button>
                  {/* </NavLink> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ResetNewPassword;
