import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { API_URL } from "../config";
import {
  getSiteSettigs,
  addSiteSettigs,
  updateSiteSettigs,
} from "../actions/settings";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import toast from "react-hot-toast";

const initialFormValue = {
  siteName: "",
  address: "",
  sitelogo: "",
  contactNo: "",
  supportMail: "",
  socialLinks: [],
};
function SiteSettings() {
  const [count, setCount] = useState(1);
  const [settings, setSettings] = useState();

  const [formvalue, setFormvalue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {
    try {
      let { status, message, result } = await getSiteSettigs();
      setFormvalue(result);
      setCount(result?.socialLinks?.length);
    } catch (err) {
      console.log(err, "errr");
    }
  };

  const validation = (value) => {
    try {
      let error = errors;
      if (isEmpty(value.siteName)) {
        error["siteName"] = "Field is required";
      } else {
        error["siteName"] = "";
      }
      if (isEmpty(value.address)) {
        error["address"] = "Field is required";
      } else {
        error["address"] = "";
      }
      if (isEmpty(value.contactNo)) {
        error["contactNo"] = "Field is required";
      } else {
        error["contactNo"] = "";
      }
      if (isEmpty(value.supportMail)) {
        error["supportMail"] = "Field is required";
      } else {
        error["supportMail"] = "";
      }
      if (isEmpty(value.socialLinks)) {
        error["socialLinks"] = "Field is required";
      } else {
        error["socialLinks"] = "";
      }

      if (typeof value.sitelogo != "object" && isEmpty(value.sitelogo)) {
        error["sitelogo"] = "Field is required";
      } else {
        error["sitelogo"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let Formvalue;
    if (name == "sitelogo") {
      Formvalue = { ...formvalue, [name]: event.target.files[0] };
    } else {
      Formvalue = { ...formvalue, [name]: value };
    }
    setFormvalue(Formvalue);
    // setErrors(validation(Formvalue));
  };

  const handleSocialLinks = async (e, index) => {
    try {
      const { name, value } = e.target;
      let arr = [...formvalue.socialLinks];
      let val = arr[index] ? arr[index] : {};
      if (name == "icon") {
        val[`${name}`] = e.target.files[0];
      } else {
        val[`${name}`] = value;
      }
      arr[index] = val;
      let Formvalue = { ...formvalue, ["socialLinks"]: arr };
      setFormvalue(Formvalue);
      // setErrors(validation(Formvalue));
    } catch (err) {
      console.log(err, "handleHighlights");
    }
  };

  const handleSubmit = async () => {
    try {
      let isValidate = validation(formvalue);
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      const formData = new FormData();
      formData.append("siteName", formvalue.siteName);
      formData.append("address", formvalue.address);
      formData.append("sitelogo", formvalue.sitelogo);
      formData.append("contactNo", formvalue.contactNo);
      formData.append("supportMail", formvalue.supportMail);
      for (let i = 0; i < formvalue.socialLinks.length; i++) {
        formData.append("images", formvalue.socialLinks[i].icon);
      }
      formData.append("socialLinks", JSON.stringify(formvalue.socialLinks));
      let { status, message, result } = await updateSiteSettigs(formData);
      if (status == "success") {
        getSiteSettigs();
        toast.success("Page Settings Updated");
      }
      getSiteSettigs();
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"Site Settings"} />
            <div className="common_page_scroller">
              <div className="mt-3 mt-sm-5 profile_holder ps-lg-3">
                <Row className="align-items-center">
                  <Col lg={7} className="mb-3 mb-lg-4">
                    <div className="rp_singleinput_holder">
                      <p className="rp_label mb-2">Site Logo</p>
                      <label
                        for="file-upload"
                        className="custom-file-upload mt-3">
                        Custom Upload
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        name="sitelogo"
                        className="hide_input"
                      />
                      <Row className="mt-3">
                        <Col lg={3}>
                          <img
                            className="img-fluid cmn__siteLogo"
                            src={
                              typeof formvalue?.sitelogo == "string"
                                ? API_URL +
                                  "/public/images/" +
                                  formvalue?.sitelogo
                                : URL.createObjectURL(formvalue?.sitelogo)
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={7} className="mb-3 mb-lg-4">
                    <div className="rp_singleinput_holder">
                      <p className="rp_label mb-2">Site Name</p>
                      <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                        <input
                          type="text"
                          placeholder="Site Name"
                          name="siteName"
                          value={formvalue?.siteName}
                          onChange={handleChange}
                          className="rp_singleInput flex-grow-1"
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg={7} className="mb-3 mb-lg-4">
                    <div className="rp_singleinput_holder">
                      <p className="rp_label mb-2">Address</p>
                      <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                        <input
                          type="text"
                          placeholder="Site Name"
                          name="address"
                          value={formvalue?.address}
                          onChange={handleChange}
                          className="rp_singleInput flex-grow-1"
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg={7} className="mb-3 mb-lg-4">
                    <div className="rp_singleinput_holder">
                      <p className="rp_label mb-2">Contact No</p>
                      <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                        <input
                          type="text"
                          placeholder="Site Name"
                          name="contactNo"
                          value={formvalue?.contactNo}
                          onChange={handleChange}
                          className="rp_singleInput flex-grow-1"
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg={7} className="mb-3 mb-lg-4">
                    <div className="rp_singleinput_holder">
                      <p className="rp_label mb-2">Support Mail</p>
                      <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                        <input
                          type="text"
                          placeholder="Site Name"
                          name="supportMail"
                          value={formvalue?.supportMail}
                          onChange={handleChange}
                          className="rp_singleInput flex-grow-1"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col lg={7} className="mb-2 mb-lg-2">
                    <div className="rp_singleinput_holder">
                      <p className="rp_label mb-2 rp_lable_theme">
                        Social Links
                      </p>
                    </div>
                  </Col>
                </Row>

                {[...Array(count)].map((item, index) => {
                  let i = index;
                  return (
                    <>
                      <Row className="align-items-center">
                        <Col lg={7} className="mb-3 mb-lg-4">
                          <div className="rp_singleinput_holder">
                            <p className="rp_label mb-2">Social Name</p>
                            <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                              <input
                                type="text"
                                placeholder="Social Name"
                                name="name"
                                value={formvalue?.socialLinks[index]?.name}
                                className="rp_singleInput flex-grow-1"
                                onChange={(e) => {
                                  handleSocialLinks(e, i);
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col lg={7} className="mb-3 mb-lg-4">
                          <div className="rp_singleinput_holder">
                            <p className="rp_label mb-2">Social Link</p>
                            <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                              <input
                                type="text"
                                name="link"
                                value={formvalue?.socialLinks[index]?.link}
                                placeholder="Social Link"
                                className="rp_singleInput flex-grow-1"
                                onChange={(e) => {
                                  handleSocialLinks(e, i);
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col lg={7} className="mb-3 mb-lg-4">
                          <div className="rp_singleinput_holder">
                            <p className="rp_label mb-2">Social Logo</p>
                            <label
                              for={`file-upload${i}`}
                              className="custom-file-upload mt-3">
                              Custom Upload
                            </label>
                            <input
                              id={`file-upload${i}`}
                              type="file"
                              onChange={(e) => {
                                handleSocialLinks(e, i);
                              }}
                              name="icon"
                              className="hide_input"
                            />
                          </div>
                          <Row className="mt-3">
                            <Col lg={3}>
                              <img
                                className="img-fluid cmn__siteLogo"
                                src={
                                  // API_URL +
                                  // "/public/images/" +
                                  // formvalue?.socialLinks[index]?.icon

                                  formvalue?.socialLinks?.length > 0 &&
                                  typeof formvalue?.socialLinks[index]?.icon ==
                                    "string"
                                    ? API_URL +
                                      "/public/images/" +
                                      formvalue?.socialLinks[index]?.icon
                                    : typeof formvalue?.socialLinks[index]
                                        ?.icon == "undefined"
                                    ? ""
                                    : URL.createObjectURL(
                                        formvalue?.socialLinks[index]?.icon
                                      )
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  );
                })}

                <Row className="align-items-center">
                  <Col lg={5} className="mb-4">
                    <div className="rp_singleinput_holder">
                      <button
                        className="orange_small_primary mt-4"
                        onClick={() => {
                          setCount(count + 1);
                        }}>
                        Add
                      </button>
                      <button
                        className="orange_small_secondary mt-4 ms-3"
                        disabled={count == 1 ? "disabled" : ""}
                        onClick={() => {
                          setCount(count - 1);
                        }}>
                        Delete
                      </button>
                    </div>
                  </Col>
                </Row>

                <div className="d-flex gap-3 mb-5">
                  <button className="secondary_btn">Cancel</button>
                  <button
                    className="orange_primary_btn"
                    onClick={() => {
                      handleSubmit();
                    }}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SiteSettings;
