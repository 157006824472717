import logo from "./logo.svg";
import "./App.css";
import { Routes } from "react-router-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "./Screens/Dashboard";
import "../src/assets/css/Style.css";
import UserWallet from "./Screens/UserWallet";
import Network from "./Screens/Network";
import ResetPassword from "./Screens/ResetPassword";
import Exchange from "./Screens/Exchange";
import SupportTicket from "./Screens/SupportTicket";
import Profile from "./Screens/Profile";
import Settings from "./Screens/Settings";
import Subscription from "./Screens/Subscription";
import AddSettings from "./Screens/AddSettings";
import Pairs from "./Screens/Pairs";
import Exchanges from "./Screens/Exchanges";
import Users from "./Screens/Users";
import MarketOpportunities from "./Screens/MarketOpportunities";
import UserSettings from "./Screens/UserSettings";
import SiteSettings from "./Screens/SiteSettings";
import TradeHistory from "./Screens/TradeHistory";
import ScheduleHistory from "./Screens/ScheduleHistory";
import Subadmin from "./Screens/Subadmin";
import AddSubscription from "./Screens/AddSubscription";
import EditSubscription from "./Screens/EditSubscription";

import ForgotPassword from "./Screens/ForgotPassword";
import ResetNewPassword from "./Screens/ResetNewPassword";
import OTPVerification from "./Screens/OTPVerification";

import LoginPage from "./Screens/LoginPage";
import Tokens from "./Screens/Tokens";

// import { ToastContainer } from "react-toastify";
import toast, { Toaster } from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store } from "./Redux/Store";
import ConditionRoute from "./Components/ConditionRoute";
import NotFound from "./Components/NotFound";
import Schedules from "./Screens/Schedules";
function App() {
  let records = store.getState();
  let restriction = records.isRun.restriction;
  return (
    <>
      <Provider store={store}>
        {/* <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetNewPassword" element={<ResetNewPassword />} />
          <Route path="/otpVerification" element={<OTPVerification />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/userWallet" element={<UserWallet />} />
          <Route path="/supportTicket" element={<SupportTicket />} />
          <Route path="/network" element={<Network />} />
          <Route path="/exchange" element={<Exchange />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/userSettings" element={<UserSettings />} />
          <Route path="/siteSettings" element={<SiteSettings />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/resetPassord" element={<ResetPassword />} />
          <Route path="/addSettings" element={<AddSettings />} />
          <Route path="/tradeHistory" element={<TradeHistory />} />
          <Route path="/scheduleHistory" element={<ScheduleHistory />} />
          <Route path="/subAdmin" element={<Subadmin />} />
          <Route path="/pairs" element={<Pairs />} />
          <Route path="/exchanges" element={<Exchanges />} />
          <Route path="/users" element={<Users />} />
          <Route path="/opportunities" element={<MarketOpportunities />} />
          <Route path="/addSubscription" element={<AddSubscription />} />
          <Route path="/tokens" element={<Tokens />} />
        </Routes> */}

        <BrowserRouter>
          {/* <ToastContainer /> */}
          {/* <div className="App"> */}
          <div><Toaster /></div>
          <Switch>
            <ConditionRoute
              exact
              path="/"
              component={LoginPage}
              type={"public"}></ConditionRoute>

            <ConditionRoute
              exact
              path="/forgotPassword"
              component={ForgotPassword}
              type={"public"}></ConditionRoute>

            <ConditionRoute
              exact
              path="/resetNewPassword"
              component={ResetNewPassword}
              type={"public"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/otpVerification"
              component={OTPVerification}
              type={"public"}></ConditionRoute>

            <ConditionRoute
              exact
              path="/dashboard"
              component={Dashboard}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/userWallet"
              component={UserWallet}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/supportTicket"
              component={SupportTicket}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/network"
              component={Network}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/schedules"
              component={Schedules}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/exchange"
              component={Exchange}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/profile"
              component={Profile}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/settings"
              component={Settings}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/userSettings"
              component={UserSettings}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/siteSettings"
              component={SiteSettings}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/subscription"
              component={Subscription}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/resetPassord"
              component={ResetPassword}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/addSettings"
              component={AddSettings}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/tradeHistory"
              component={TradeHistory}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/scheduleHistory"
              component={ScheduleHistory}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/subAdmin"
              component={Subadmin}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/pairs"
              component={Pairs}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/exchanges"
              component={Exchanges}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/users"
              component={Users}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/opportunities"
              component={MarketOpportunities}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/addSubscription"
              component={AddSubscription}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/tokens"
              component={Tokens}
              type={"private"}></ConditionRoute>
            <ConditionRoute
              exact
              path="/editsubscription"
              component={EditSubscription}
              type={"private"}></ConditionRoute>
          </Switch>
          {/* <Route exact path="*" component={NotFound} /> */}
          {/* </div> */}
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
