import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import nav from "../config/routes";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import { EditAdmin } from "../actions/admin";
import toast from "react-hot-toast";
const initialFormValue = {
  name: "",
  email: "",
  password: "",
  role: "subadmin",
  restriction: [],
};
const EditsubAdminModal = ({ show, handleClose, record, getSubadminList }) => {
  const [formvalue, setFormvalue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (!isEmpty(record)) {
      setFormvalue(record);
      setSelectedOption1(record?.restriction);
    }
  }, [record]);

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);
      setErrors(validation(Formvalue));
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const validation = (value) => {
    try {
      let error = errors;
      if (isEmpty(value.name)) {
        error["name"] = "Field is required";
      } else {
        error["name"] = "";
      }
      if (isEmpty(value.email)) {
        error["email"] = "Field is required";
      } else {
        error["email"] = "";
      }
      if (isEmpty(value.restriction)) {
        error["restriction"] = "Field is required";
      } else {
        error["restriction"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      const { email, name, password, role } = formvalue;
      let oj = {
        email: formvalue.email,
        name: formvalue.name,
        restriction: selectedOption1,
      };
      let isValidate = validation(oj);
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let { status, message, result } = await EditAdmin(oj);
      if (status == "success") {
        toast.success("Sub Admin Updated Successfully")
        handleClose();
        getSubadminList();
      }
      else{
        toast.error("Sub Admin Updated Failed")
        handleClose();
        getSubadminList();
      }
      handleClose();
      getSubadminList();
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  const [options, setOptions] = useState();

  const setNavOptions = (async) => {
    let data =
      nav &&
      nav.length > 0 &&
      nav.map((item, i) => {
        return {
          label: item.name,
          value: item.path,
        };
      });
    console.log(data, "data", nav);
    setOptions(data);
  };
  useEffect(() => {
    setNavOptions();
  }, []);

  const options1 = [
    { value: "starter", label: "Starter" },
    { value: "plus", label: "Plus" },
    { value: "premium", label: "Premium" },
  ];

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#5fe44b"
          : isSelected
          ? "#5fe44b"
          : isFocused
          ? "#5fe44b"
          : "#000",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "43px",
      padding: "0px 10px",

      backgroundColor: "transparent",
      color: "red",
      border: "none",
      borderRadius: 0,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
      padding: "0px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#ffffff",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,
      width: "100%",

      borderRadius: 5,
      background: "transparent",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#fff",
      };
    },
  };

  const [selectedOption1, setSelectedOption1] = useState("Theme 1");
  const [pwd, setPwd] = useState(false);

  const handleChange1 = (e) => {
    console.log(e, "selectedOptionselectedOption");

    setSelectedOption1(
      e.map((val) => {
        return val.value;
      })
    );
  };
  console.log(selectedOption1, "selectedOption1");

  return (
    <>
      <Modal
        centered
        className="cmn_modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div className="cmn_modal_header d-flex justify-content-between align-items-center">
            <p className="cmn_modal_title">Add Subadmin</p>
            <button
              className="cmn_modal_closer rounded-5"
              onClick={handleClose}>
              <i class="fa-solid fa-xmark" />
            </button>
          </div>

          <div className="mt-4">
            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Name</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  onChange={handleChange}
                  value={formvalue.name}
                  className="rp_singleInput flex-grow-1"
                />
              </div>
            </div>

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Email</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  value={formvalue.email}
                  className="rp_singleInput flex-grow-1"
                />
              </div>
            </div>

            {/* <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Password</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type={pwd ? "text" : "password"}
                  placeholder="Enter Password"
                  name="password"
                  value={formvalue.password}
                  onChange={handleChange}
                  className="rp_singleInput flex-grow-1"
                />

                <i
                  class={pwd ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"}
                  onClick={() => setPwd(!pwd)}
                />
              </div>
            </div> */}

            {/* <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Role</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="role"
                  name="role"
                  value={formvalue.name}
                  onChange={handleChange}
                  className="rp_singleInput flex-grow-1"
                />
              </div>
            </div> */}

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Restriction</p>
              <div className="rp_input_holder rounded-2 px-0">
                <Select
                  onChange={(e) => handleChange1(e)}
                  options={options}
                  defaultValue="theme1"
                  styles={stylesgraybg}
                  isSearchable={false}
                  isMulti
                  classNamePrefix="customselect"
                  value={
                    selectedOption1 && selectedOption1?.length > 0
                      ? options?.filter((el) => {
                          if (selectedOption1.includes(el.value)) {
                            return el;
                          }
                        })
                      : []
                  }
                  // defaultInputValue="Theme 1"
                  // menuIsOpen
                />
              </div>
            </div>

            <button
              className="orange_small_primary mt-3"
              onClick={() => {
                handleSubmit();
                handleClose();
              }}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditsubAdminModal;
