import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";
import EditUsersModal from "../Modals/EditUsersModal";
import DeleteUsersModal from "../Modals/DeleteUsersModal";
import AddUsersModal from "../Modals/AddUserModal";
import { getUserList } from "../actions/admin";
import Exportexcel from "../Components/Excelexport";
import Papa from "papaparse";
import { addNewUserCSV } from "../actions/admin";
import toast from "react-hot-toast";

const Users = () => {
  const [userLits, setUserList] = useState();
  const [pageNumer, setPageNumer] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [formvalue, setFormvalue] = useState({});
  const [errors, setErrors] = useState({});

  const [fileName, setFileName] = useState();
  const [fileValues, setFileValues] = useState();

  const getUserAllList = async () => {
    try {
      let reqData = {
        page: pageNumer,
        limit: limit,
      };
      let { status, message, result, count } = await getUserList(reqData);
      if (status == "success") {
        setUserList(result);
        setCount(count);
      }
    } catch {}
  };

  const address_showing = (item) => {
    if (item && item.toString().length > 10) {
      var slice_front = item.slice(0, 9);
      var slice_end = item.slice(item.length - 9, item.length - 1);
      return slice_front + "...." + slice_end;
    } else return item;
  };

  const handlePagination = async (index) => {
    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };
    // let skip = (index.page_number - 1) * index.page_size;
    // let loadinterval = index.page_number * index.page_size;
    setPageNumer(index.page_number);
    setLimit(index.page_size);
    const { status, message, result, count } = await getUserList(reqData);
    setUserList(result);
    setCount(count);
    // setInterval(loadinterval);
  };

  useEffect(() => {
    getUserAllList();
  }, []);

  // userLits?.map((val) => {
  //   val.walletAddress = address_showing(val.walletAddress);
  // });
  // add modal

  const [showAddUsers, setShowAddUsers] = useState(false);

  const handleShowAddUsers = () => setShowAddUsers(true);
  const handleCloseAddUsers = () => setShowAddUsers(false);

  // edid Exchange modal
  const [showEditUser, setShowEditUser] = useState(false);
  const [editRecord, setEditRecord] = useState();
  const [deleteRecord, setDeleteRecord] = useState({});

  const handleShowEditUser = (record) => {
    setEditRecord(record);
    setShowEditUser(true);
  };

  const handleCloseEditUser = () => {
    setShowEditUser(false);
    setEditRecord({});
  };

  // delete Exchange modal

  const [showDeleteUsers, setShowDeleteUsers] = useState(false);

  const handleShowDeleteUsers = (record) => {
    setDeleteRecord(record);
    setShowDeleteUsers(true);
  };
  const handleCloseDeleteUsers = () => setShowDeleteUsers(false);

  const changeHandler = async (event) => {
    let splitFile = event.target.files[0].name.split(".");
    if (splitFile[splitFile.length - 1] != "csv") {
      // toastAlert('error', "Select your csv file !", 'addTemplate')
      // toast.error('Select your csv file !', {
      //     position: toast.POSITION.TOP_CENTER
      // });
      return false;
    }
    const valuesArray = [];

    setFileName(event.target.files[0].name);

    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        // this.setState({ valuesArray: results.data })

        // Iterating data to get column name and their values
        results.data.map((d) => {
          valuesArray.push(Object.values(d));
        });
        // Filtered Values
        setFileValues(valuesArray);

        let { status, message, result } = await addNewUserCSV(valuesArray);
        if (status == "success") {
          getUserAllList();
          toast.success("File Imported Successfully");
          setFileName("");
        } else {
          getUserAllList();
          toast.success("File Imported Failed");
          setFileName("");
        }
      },
    });
  };

  const columns = [
    {
      key: "name",
      text: "Name",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
    },
    {
      key: "walletAddress",
      text: "Wallet Address",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <p>{address_showing(record.walletAddress)}</p>
          </div>
        );
      },
    },
    {
      key: "status",
      text: "Status",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <p>{record.status ? "Active" : "In Active"}</p>
          </div>
        );
      },
    },
    {
      key: "plan",
      text: "Plan",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex justify-content-center align-items-center gap-2">
            {console.log(record.plan, "record.plan")}
            <p>{record?.plan?.length > 0 ? record?.plan[0]?.name : "-"}</p>
          </div>
        );
      },
    },
    {
      key: "balance",
      text: "Balance",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <p>{record.balance}</p>
          </div>
        );
      },
    },
    {
      key: "profit",
      text: "Profit",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <p>{record.profit}</p>
          </div>
        );
      },
    },
    // {
    //   key: "plan",
    //   text: "Plan",
    //   className: "plan",
    //   align: "center",
    //   sortable: false,
    // },
    {
      key: "action",
      text: "Action",
      className: "activity",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <button
              className="cmn_plain_btn"
              onClick={() => {
                handleShowEditUser(record);
              }}>
              <img
                src={require("../assets/images/editer.svg").default}
                className="img-fluid table_activity_img"
              />{" "}
            </button>
            <button
              className="cmn_plain_btn"
              onClick={() => {
                handleShowDeleteUsers(record);
              }}>
              <img
                src={require("../assets/images/trash.svg").default}
                className="img-fluid table_activity_img"
              />{" "}
            </button>
          </div>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",
    no_data_text: "No Email Templates found!",
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: true,
    show_pagination: true,
    show_info: false,
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {},
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {},
      onDoubleClick: (event) => {},
    },
  ];
  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"Users"} />

            <div className="text-end mb-4">
              <button
                className="orange_small_primary"
                onClick={() => {
                  handleShowAddUsers(true);
                }}>
                Add
              </button>
            </div>
            <div className="rp_singleinput_holder mb-3">
              {/* <Row>
                <Col md={6} sm={8} xs={12}>
                <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Search..."
                  name="name"
                  className="rp_singleInput flex-grow-1"
                />
              </div>
                </Col>
              </Row> */}
            </div>
            <div className="common_page_scroller pb-5 mt-3 mt-sm-5 pe-2">
              <div className="exchange_table_holder dashboard_box rounded-3 mt-4">
                <div className="d-flex justify-content-between align-items-center px-2">
                  <div className="cmn_extraBtnsHolder mt-3 d-flex justify-content-start align-items-center gap-2">
                    <p className="m-0 cmn_extraBtnsLabel">Exports :</p>
                    <Exportexcel excelData={userLits} fileName={"users"} />
                  </div>

                  <div className="d-flex justif-content-end align-items-center gap-2">
                    <p className="m-0 cmn_extraBtnsLabel">Import :</p>
                    <button className="exchange_tableFileUploader table_extrabtns position-relative">
                      <input
                        type="file"
                        onChange={(e) => {
                          changeHandler(e);
                        }}
                      />
                      <i class="fa-solid fa-file"></i>
                    </button>
                    <span>{fileName}</span>
                  </div>
                </div>
                <ReactDatatable
                  config={config}
                  records={userLits}
                  columns={columns}
                  extraButtons={extraButtons}
                  dynamic={true}
                  total_record={count}
                  onChange={(e) => {
                    handlePagination(e);
                  }}
                  filterRecords={(e) => {}}
                  filterData={(e) => {}}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* modals */}

      <AddUsersModal
        show={showAddUsers}
        getUserAllList={getUserAllList}
        handleClose={handleCloseAddUsers}
      />

      <EditUsersModal
        show={showEditUser}
        record={editRecord}
        getUserAllList={getUserAllList}
        handleClose={handleCloseEditUser}
      />
      <DeleteUsersModal
        show={showDeleteUsers}
        record={deleteRecord}
        getUserAllList={getUserAllList}
        handleClose={handleCloseDeleteUsers}
      />
      {/* end of modals */}
    </>
  );
};

export default Users;
