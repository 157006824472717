export const ONEDAYINSECONDS = 86400;
export const BURN = "0x000000000000000000000000000000000000dEaD";
export const BLOCKS_PER_YEAR = 10512000;
export const PROJECT_ID = "b8a1daa2dd22335a2fe1d2e139980ae0";

export const CHAINS = [
  {
    NAME: "Binancee",
    RPC_URL:
      "https://serene-alpha-violet.bsc.quiknode.pro/e412e873ce5cb8cb3d47c803442b4c81a1344903/",
    CHAIN_ID: 56,
  },

  {
    NAME: "Polygonn",
    RPC_URL: "https://polygon-bor-rpc.publicnode.com",
    CHAIN_ID: 137,
  },
];

export const RPC_LISTS = {
  56: [
    "https://serene-alpha-violet.bsc.quiknode.pro/e412e873ce5cb8cb3d47c803442b4c81a1344903/",
    "https://bsc-dataseed4.defibit.io",
    "https://bsc-dataseed2.bnbchain.org",
    "https://bsc-dataseed4.ninicoin.io",
    "https://bsc-dataseed4.bnbchain.org",
    "https://bsc-dataseed1.ninicoin.io",
    "https://bsc-dataseed3.bnbchain.org",
  ],
  137: [
    "https://polygon-rpc.com",
    "https://rpc-mainnet.maticvigil.com",
    "https://polygon.rpc.blxrbdn.com",
    "https://polygon-pokt.nodies.app",
  ],
};
