import React from "react";
import { Modal } from "react-bootstrap";
import { deleteSinglePair } from "../actions/currency";
import toast from "react-hot-toast";

const DeletePairModal = ({ show, handleClose, record, getAllPairs }) => {
  const deletePair = async () => {
    try {
      let { status, message, result } = await deleteSinglePair({ _id: record });
      if (status == "success") {
        toast.success("Pair Deleted Successfully");
        getAllPairs();
      } else {
        toast.success("Pair Deleted Failed");
        getAllPairs();
      }
    } catch (err) {
      console.log("deletePair_err");
    }
  };
  return (
    <>
      <Modal
        centered
        className="cmn_modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div className="cmn_modal_header d-flex justify-content-between align-items-center">
            <p className="cmn_modal_title">Delete Pairs</p>
            <button
              className="cmn_modal_closer rounded-5"
              onClick={handleClose}>
              <i class="fa-solid fa-xmark" />
            </button>
          </div>

          <div className="mt-3">
            <p className="dash_graymed_text">
              Are you sure delete these pairs.
            </p>
            <button
              className="orange_small_primary mt-5"
              onClick={() => {
                handleClose();
                deletePair();
              }}>
              Done
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeletePairModal;
