import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";
import AddTokenModal from "../Modals/AddTokenModal";
import EditTokenModal from "../Modals/EditTokenModal";
import DeleteTokenModal from "../Modals/DeleteTokenModal";
import { getAllTokens } from "../actions/currency";
import { address_showing } from "../lib/displayStatus";
const Tokens = () => {
  useEffect(() => {
    getTokens();
  }, []);

  const getTokens = async () => {
    try {
      let reqData = {
        page: pageNumer,
        limit: limit,
      };
      let { status, message, result, count } = await getAllTokens(reqData);
      setTokens(result);
      setCount(count);
    } catch (err) {
      console.log("getAllTokens_err", err);
    }
  };

  // add pari modal
  const [showAddToken, setShowAddToken] = useState(false);
  const [editRecord, setEditRecord] = useState({});
  const [deleteRecord, setDeleteRecord] = useState({});

  const handleShowAddToken = () => setShowAddToken(true);
  const handleCloseAddToken = () => setShowAddToken(false);

  // edid pari modal
  const [showEditToken, setShowEditToken] = useState(false);

  const handleShowEditToken = (record) => {
    setEditRecord(record);
    setShowEditToken(true);
  };
  const handleCloseEditToken = () => {
    setShowEditToken(false);
    setEditRecord({});
  };
  // delete Token modal

  const [showDeleteToken, setShowDeleteToken] = useState(false);

  const handleShowDeleteToken = (record) => {
    setDeleteRecord(record);
    setShowDeleteToken(true);
  };
  const handleCloseDeleteToken = () => setShowDeleteToken(false);

  const [tokens, setTokens] = useState();
  const [pageNumer, setPageNumer] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const handlePagination = async (index) => {
    let reqData = {
      page: index.page_number,
      limit: index.page_size,
    };
    // let skip = (index.page_number - 1) * index.page_size;
    // let loadinterval = index.page_number * index.page_size;
    setPageNumer(index.page_number);
    setLimit(index.page_size);
    const { status, message, result, count } = await getAllTokens(reqData);
    setTokens(result);
    setCount(count);
    // setInterval(loadinterval);
  };

  // tokens?.map((val) => {
  //   val.address = address_showing(val.address);
  //   val.logoURI = address_showing(val.logoURI);
  // });

  const state = [
    {
      name: <p className="exchange_table_values text-center">Demo</p>,
      tokenAddressOne: (
        <p className="exchange_table_values text-center">t34tegr34tgsdfas3g</p>
      ),
      tokenAddressTwo: (
        <p className="exchange_table_values text-center">afdg334q5h45hg435h</p>
      ),
    },
    {
      name: <p className="exchange_table_values text-center">Demo</p>,
      tokenAddressOne: (
        <p className="exchange_table_values text-center">t34tegr34tgsdfas3g</p>
      ),
      tokenAddressTwo: (
        <p className="exchange_table_values text-center">afdg334q5h45hg435h</p>
      ),
    },
    {
      name: <p className="exchange_table_values text-center">Demo</p>,
      tokenAddressOne: (
        <p className="exchange_table_values text-center">t34tegr34tgsdfas3g</p>
      ),
      tokenAddressTwo: (
        <p className="exchange_table_values text-center">afdg334q5h45hg435h</p>
      ),
    },
  ];

  const columns = [
    {
      key: "name",
      text: "Name",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
    },
    {
      key: "address",
      text: "Address",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <p>{address_showing(record.address)}</p>
          </div>
        );
      },
    },
    {
      key: "symbol",
      text: "Symbol",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
    },
    {
      key: "decimals",
      text: "Decimals",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
    },
    {
      key: "logoURI",
      text: "Logo URI",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <p>{address_showing(record.logoURI)}</p>
          </div>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "activity",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <button
              className="cmn_plain_btn"
              onClick={() => {
                handleShowEditToken(record);
              }}>
              <img
                src={require("../assets/images/editer.svg").default}
                className="img-fluid table_activity_img"
              />{" "}
            </button>
            <button
              className="cmn_plain_btn"
              onClick={() => {
                handleShowDeleteToken(record._id);
              }}>
              <img
                src={require("../assets/images/trash.svg").default}
                className="img-fluid table_activity_img"
              />{" "}
            </button>
          </div>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",
    no_data_text: "No Email Templates found!",
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: true,
    show_info: false,
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {},
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {},
      onDoubleClick: (event) => {},
    },
  ];
  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"Tokens"} />
            <div className="text-end mb-4">
              <button
                className="orange_small_primary"
                onClick={() => {
                  handleShowAddToken(true);
                }}>
                Add
              </button>
            </div>
            <div className="common_page_scroller pb-5 mt-3 mt-sm-5 pe-2">
              <div className="exchange_table_holder dashboard_box rounded-3 mt-4">
                <ReactDatatable
                  config={config}
                  records={tokens}
                  columns={columns}
                  extraButtons={extraButtons}
                  dynamic={true}
                  total_record={count}
                  onChange={(e) => {
                    handlePagination(e);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* modals */}
      <AddTokenModal
        show={showAddToken}
        getTokens={getTokens}
        handleClose={handleCloseAddToken}
      />

      <EditTokenModal
        show={showEditToken}
        record={editRecord}
        getTokens={getTokens}
        handleClose={handleCloseEditToken}
      />
      <DeleteTokenModal
        show={showDeleteToken}
        record={deleteRecord}
        getTokens={getTokens}
        handleClose={handleCloseDeleteToken}
      />
      {/* end of modals */}
    </>
  );
};

export default Tokens;
