import React, { useRef, useState, Fragment } from "react";
import Header from "../Components/Header";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Lottie from "lottie-react";
import bitcoin from "../assets/lotties/ye.json";
import thunder from "../assets/lotties/u.json";
import Select from "react-select";
import ReactApexChart from "react-apexcharts";
import ReactDatatable from "@ashvin27/react-datatable";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Images } from "../Images";

function Dashboard() {
  const switcher = useSelector((state) => state.switcher);
  let { restriction } = useSelector((state) => state.isRun);
  console.log(restriction, "restrictionrestriction");

  const dispatch = useDispatch();

  const coinRef = useRef();
  const ref = useRef();
  const [chartPeriod, setChartPeriod] = useState("1h");

  const [closer, setCloser] = useState();

  const [selected, setSelected] = useState({
    value: "BNB",
    label: "BNB",
    img: require("../assets/images/bitcoin.svg"),
  });
  const options = [
    {
      value: "delete",
      label: "Delete",
    },
    {
      value: "read",
      label: "Read",
    },
    {
      value: "save",
      label: "Save",
    },
  ];

  const options2 = [
    {
      value: "thunder",
      label: "Thunder",
      img: require("../assets/images/viothunder.svg"),
    },
    {
      value: "bitcoin",
      label: "Bitcoin",
      img: require("../assets/images/bitcoin.svg"),
    },
  ];
  const customOptionRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component p-2"
      onClick={() => {
        setSelected({ label, value, img: data.img });
        coinRef.current.blur();
        setCloser(!closer);
      }}>
      {console.log("sdhguisd", label, data, value)}
      <img
        src={data.img.default}
        style={{ width: "25px", marginRight: "10px" }}
      />
      {label}
    </div>
  );

  const lineData = {
    options: {
      colors: [`${switcher ? "#7638EF" : "#FF8606"}`],
      chart: {
        height: 300,
        type: "area",
        foreColor: "#fff",
        toolbar: {
          show: false,
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
          border: {
            show: false,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
          border: {
            show: false,
          },
        },
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "text",
        categories: [
          "08:00 AM",
          "09:00 AM",
          "10:00 AM",
          "11:00 AM",
          "12:00 PM",
        ],
      },
      yaxis: {
        type: "text",
        data: ["kk", "sdfdf", "dfdf", "dfd"],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
    stroke: {
      colors: ["transperant"],
    },
    fill: {
      colors: ["#FF8606"],
      background: ["#FF8606"],
    },
    series: [
      {
        name: "series1",
        data: ["34.55k", "40k", "28k", "51k", "42k"],
      },
    ],
  };

  const state = {
    records: [
      {
        name: (
          <div className="dash_table_left d-flex justify-content-start align-items-centre gap-2">
            <img
              src={require("../assets/images/greenarrow.svg").default}
              className="img-fluid dash_table_arrowImg"
            />
            <div className="dash_table_statusHolder">
              <p className="dash_table_status">Send</p>
              <p className="dash_graymed_text">Feb 03 . 1043 AM</p>
            </div>
          </div>
        ),
        value: (
          <div className="dash_table_left">
            <div className="dash_table_statusHolder text-end">
              <p className="dash_table_status">
                +0.000253 {switcher ? "LN" : "BTC"}
              </p>
              <p className="dash_graymed_text">Confirmed</p>
            </div>
          </div>
        ),
      },
      {
        name: (
          <div className="dash_table_left d-flex justify-content-start align-items-centre gap-2">
            <img
              src={require("../assets/images/redarrow.svg").default}
              className="img-fluid dash_table_arrowImg"
            />
            <div className="dash_table_statusHolder">
              <p className="dash_table_status">Send</p>
              <p className="dash_graymed_text">Feb 03 . 1043 AM</p>
            </div>
          </div>
        ),
        value: (
          <div className="dash_table_left">
            <div className="dash_table_statusHolder text-end">
              <p className="dash_table_status">
                +0.000253 {switcher ? "LN" : "BTC"}
              </p>
              <p className="dash_graymed_text">Confirmed</p>
            </div>
          </div>
        ),
      },
    ],
  };

  const columns = [
    {
      key: "name",
      className: "name",
      align: "left",
      sortable: false,
    },
    {
      key: "value",
      className: "address",
      align: "right",
      sortable: false,
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",
    no_data_text: "No Email Templates found!",
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: false,
    show_info: false,
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const styles2 = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
        ? "#00CABA"
        : isHovered
        ? "red"
        : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
          ? "#16EBC3"
          : isFocused
          ? "#16EBC3"
          : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "15px",
      };
    },
    indicatorSeparator: (styles) => ({ display: "none" }),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      paddingLeft: "60px",
      border: "none",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "15px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      maxWidth: "150px",
      border: "none",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      width: "0px",
      position: "absolute",
      right: 0,
      top: 0,
      border: "none",
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const styles = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#161414"
        : isSelected
        ? "#161414"
        : isHovered
        ? "161414"
        : "#141313",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#161414"
          : isSelected
          ? "#161414"
          : isFocused
          ? "#161414"
          : "#141313",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "#fff",
        fontSize: "13px",
      };
    },

    indicatorSeparator: () => ({ display: "none" }),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "none",
      width: "100px",
      backgroundColor: isHovered
        ? "#141313"
        : isSelected
        ? "#141313"
        : isFocused
        ? "#141313"
        : "#141313",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "none",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      border: "none",
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#343434",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"Dashboard"} switcher={switcher} />

            <div className="common_page_scroller mt-3 mt-sm-5 pe-2">
              <Row className="">
                {restriction?.length > 0 ? (
                  restriction?.includes("/pairs") ? (
                    <Col lg={3} md={6} sm={6} className="mb-4 mb-lg-0">
                      <NavLink className="cmn__navLinks" to="/pairs">
                        <div className="dashboard_box p-3 rounded-3 d-flex flex-column gap-4">
                          <div className="dashbox_topflex d-flex justify-content-between align-items-center">
                            <div className="dash_lottie_holder position-relative">
                              <img
                                src={Images.pairs}
                                className="img-fluid dash_logoImg"
                              />
                            </div>
                          </div>
                          <div className="dashbox_bottomflex d-flex justify-content-between align-items-end">
                            <div className="">
                              <p className="dash_graymed_text">Pairs</p>
                              {/* <p className='dash_valuewhite_text mt-1'>$ 1,42,0000</p> */}
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    </Col>
                  ) : (
                    ""
                  )
                ) : (
                  <Col lg={3} md={6} sm={6} className="mb-4 mb-lg-0">
                    <NavLink className="cmn__navLinks" to="/pairs">
                      <div className="dashboard_box p-3 rounded-3 d-flex flex-column gap-4">
                        <div className="dashbox_topflex d-flex justify-content-between align-items-center">
                          <div className="dash_lottie_holder position-relative">
                            <img
                              src={Images.pairs}
                              className="img-fluid dash_logoImg"
                            />
                          </div>
                        </div>
                        <div className="dashbox_bottomflex d-flex justify-content-between align-items-end">
                          <div className="">
                            <p className="dash_graymed_text">Pairs</p>
                            {/* <p className='dash_valuewhite_text mt-1'>$ 1,42,0000</p> */}
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </Col>
                )}

                {restriction?.length > 0 ? (
                  restriction?.includes("/exchanges") ? (
                    <Col lg={3} md={6} sm={6} className="mb-4 mb-lg-0">
                      <NavLink className="cmn__navLinks" to="/exchanges">
                        <div className="dashboard_box p-3 rounded-3 d-flex flex-column gap-4">
                          <div className="dashbox_topflex d-flex justify-content-between align-items-center">
                            <div className="dash_lottie_holder position-relative">
                              <img
                                src={Images.exchanger}
                                className="img-fluid dash_logoImg"
                              />
                            </div>
                          </div>
                          <div className="dashbox_bottomflex d-flex justify-content-between align-items-end">
                            <div className="">
                              <p className="dash_graymed_text">Exchanges</p>
                              {/* <p className='dash_valuewhite_text mt-1'>$ 1,42,0000</p> */}
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    </Col>
                  ) : (
                    ""
                  )
                ) : (
                  <Col lg={3} md={6} sm={6} className="mb-4 mb-lg-0">
                    <NavLink className="cmn__navLinks" to="/exchanges">
                      <div className="dashboard_box p-3 rounded-3 d-flex flex-column gap-4">
                        <div className="dashbox_topflex d-flex justify-content-between align-items-center">
                          <div className="dash_lottie_holder position-relative">
                            <img
                              src={Images.exchanger}
                              className="img-fluid dash_logoImg"
                            />
                          </div>
                        </div>
                        <div className="dashbox_bottomflex d-flex justify-content-between align-items-end">
                          <div className="">
                            <p className="dash_graymed_text">Exchanges</p>
                            {/* <p className='dash_valuewhite_text mt-1'>$ 1,42,0000</p> */}
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </Col>
                )}

                {restriction?.length > 0 ? (
                  restriction?.includes("/users") ? (
                    <Col lg={3} md={6} sm={6} className="mb-4 mb-lg-0">
                      <NavLink className="cmn__navLinks" to="/users">
                        <div className="dashboard_box p-3 rounded-3 d-flex flex-column gap-4">
                          <div className="dashbox_topflex d-flex justify-content-between align-items-center">
                            <div className="dash_lottie_holder position-relative">
                              <img
                                src={Images.users}
                                className="img-fluid dash_logoImg"
                              />
                            </div>
                          </div>
                          <div className="dashbox_bottomflex d-flex justify-content-between align-items-end">
                            <div className="">
                              <p className="dash_graymed_text">Users</p>
                              {/* <p className='dash_valuewhite_text mt-1'>$ 1,42,0000</p> */}
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    </Col>
                  ) : (
                    ""
                  )
                ) : (
                  <Col lg={3} md={6} sm={6} className="mb-4 mb-lg-0">
                    <NavLink className="cmn__navLinks" to="/users">
                      <div className="dashboard_box p-3 rounded-3 d-flex flex-column gap-4">
                        <div className="dashbox_topflex d-flex justify-content-between align-items-center">
                          <div className="dash_lottie_holder position-relative">
                            <img
                              src={Images.users}
                              className="img-fluid dash_logoImg"
                            />
                          </div>
                        </div>
                        <div className="dashbox_bottomflex d-flex justify-content-between align-items-end">
                          <div className="">
                            <p className="dash_graymed_text">Users</p>
                            {/* <p className='dash_valuewhite_text mt-1'>$ 1,42,0000</p> */}
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </Col>
                )}

                {restriction?.length > 0 ? (
                  restriction?.includes("/opportunities") ? (
                    <Col lg={3} md={6} sm={6} className="mb-4 mb-lg-0">
                      <NavLink className="cmn__navLinks" to="/opportunities">
                        <div className="dashboard_box p-3 rounded-3 d-flex flex-column gap-4">
                          <div className="dashbox_topflex d-flex justify-content-between align-items-center">
                            <div className="dash_lottie_holder position-relative">
                              <img
                                src={Images.opportunity}
                                className="img-fluid dash_logoImg"
                              />
                            </div>
                          </div>
                          <div className="dashbox_bottomflex d-flex justify-content-between align-items-end">
                            <div className="">
                              <p className="dash_graymed_text">
                                Market opportunities
                              </p>
                              {/* <p className='dash_valuewhite_text mt-1'>$ 1,42,0000</p> */}
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    </Col>
                  ) : (
                    ""
                  )
                ) : (
                  <Col lg={3} md={6} sm={6} className="mb-4 mb-lg-0">
                    <NavLink className="cmn__navLinks" to="/opportunities">
                      <div className="dashboard_box p-3 rounded-3 d-flex flex-column gap-4">
                        <div className="dashbox_topflex d-flex justify-content-between align-items-center">
                          <div className="dash_lottie_holder position-relative">
                            <img
                              src={Images.opportunity}
                              className="img-fluid dash_logoImg"
                            />
                          </div>
                        </div>
                        <div className="dashbox_bottomflex d-flex justify-content-between align-items-end">
                          <div className="">
                            <p className="dash_graymed_text">
                              Market opportunities
                            </p>
                            {/* <p className='dash_valuewhite_text mt-1'>$ 1,42,0000</p> */}
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </Col>
                )}
                
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
