import React from "react";
import { Modal } from "react-bootstrap";

import { DeleteSingleUser } from "../actions/admin";
import toast from "react-hot-toast";

const DeleteUsersModal = ({ show, handleClose, record, getUserAllList }) => {
  console.log("record",record)
  const deleteUser = async () => {
    try {
      let { status, message, result } = await DeleteSingleUser({ _id: record });
      if (status == "success") {
        toast.success("User Deleted Successfully");
        getUserAllList();
      } else {
        toast.success("User Deleted Failed");
        getUserAllList();
      }
    } catch (err) {
      console.log("deleteUser_err",err);
    }
  };

  return (
    <>
      <Modal
        centered
        className="cmn_modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div className="cmn_modal_header d-flex justify-content-between align-items-center">
            <p className="cmn_modal_title">Delete User</p>
            <button
              className="cmn_modal_closer rounded-5"
              onClick={handleClose}>
              <i class="fa-solid fa-xmark" />
            </button>
          </div>

          <div className="mt-3">
            <p className="dash_graymed_text">Are you sure delete the user.</p>
            <button
              className="orange_small_primary mt-5"
              onClick={() => {
                deleteUser();
                handleClose();
              }}>
              Done
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteUsersModal;
