import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import OTPInput from "react-otp-input";
import { verifyOtp } from "../actions/admin";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

const initialFormValue = {
  otp: "",
};

const OTPVerification = () => {
  const [otp, setOtp] = useState("");
  const [formvalue, setFormvalue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const location = useLocation();

  const validation = (value) => {
    try {
      let error = { ...errors };
      if (isEmpty(value.otp)) {
        console.log(isEmpty(value.name), "nameeeeee");
        error["otp"] = "Please Fill Otp";
      } else {
        error["otp"] = "";
      }

      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  // const handleChange = (e) => {
  //   try {
  //     const { name, value } = e.target;
  //     console.log("🚀 ~ handleChange ~ name, value:", name, value);
  //     let Formvalue = { ...formvalue, [name]: value };
  //     setFormvalue(Formvalue);
  //     // setErrors(validation(Formvalue));
  //   } catch (err) {
  //     console.log(err, "handleChange");
  //   }
  // };

  const handleSubmit = async () => {
    try {
      let isValidate = validation({ otp: otp });
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let { status, message, result } = await verifyOtp({
        email: JSON.parse(location.state.datas),
        otp: otp,
      });
      if (status == "success") {
        // history.push("/resetNewPassword");
        toast.success("OTP Verified Successfully");
        history.push({
          pathname: "/resetNewPassword",
          state: { datas: JSON.parse(location.state.datas) },
        });
      } else {
        toast.success("OTP Verified Failed");
        history.push("/forgotpassword");
      }
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  return (
    <>
      <Container fluid className="common_bg login_bg position-relative">
        <Row className="justify-content-center align-items-center row_card">
          <Col xl={6} lg={6} md={8} sm={12}>
            <div className="dashboard_box p-4">
              <div className="logo_sec d-flex justify-content-center">
                <img
                  src={require("../assets/images/logo.svg").default}
                  className="img-fluid main_logo"
                />
              </div>
              <div className="profile_holder mt-3">
                <p className="header_title_big text-center text-sm-start">
                  OTP Verification
                </p>
              </div>

              <div className="profile_holder">
                <Row>
                  <Col lg={12} md={12} sm={12} className="mb-4">
                    <div className="rp_singleinput_holder">
                      <p className="rp_label mb-3 text-center">Enter OTP</p>
                      <div className="otp__holder d-flex justify-content-center">
                        <OTPInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={4}
                          name="otp"
                          //   renderSeparator={<span>-</span>}
                          renderInput={(props) => <input {...props} />}
                        />
                      </div>
                      <span className="text-danger otp__holder d-flex justify-content-center">
                        {errors.otp}
                      </span>

                      {/* <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type="text" placeholder='Enter Email Address' className='rp_singleInput flex-grow-1' />

                    </div> */}
                    </div>
                    <div className="mt-3 text-center text-sm-end">
                      <Link to="/" className="link_theme">
                        Back to Login?
                      </Link>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  {/* <NavLink to="/resetNewPassword" className="sidebar_links"> */}
                  <button
                    className="orange_small_primary"
                    onClick={() => {
                      handleSubmit();
                    }}>
                    Submit
                  </button>
                  {/* </NavLink> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OTPVerification;
