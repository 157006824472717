import React from "react";

import { useHistory } from "react-router-dom";

function SubscriptionCard({ data }) {
  const history = useHistory();
  return (
    <>
      <div className="subscription_card rounded-3">
        <div
          className={` p-3 rounded-2 ${
            data.fee <= 10
              ? "subscription_card_blueHead"
              : "subscription_card_greenHead"
          }`}>
          <p className="subsciption_title text-center">{data?.name}</p>
          <button
            className="cmn_plain_btn"
            onClick={(e) =>{
            //  let stateData = {...data}
              history.push({
                pathname: "/editsubscription",
                state: { datas: JSON.stringify(data) },
              })
            }
            }>
            <img
              src={require("../assets/images/editer.svg").default}
              className="img-fluid table_activity_img"
            />{" "}
          </button>
        </div>
        <div className="subscriptioc_card_body py-3 px-4 ">
          <p className="subsciption_price_days text-center mb-3">
            ${data.fee}/<small>{data.duration}days</small>
          </p>
          <div className="d-flex flex-column align-items-center">
            <ul>
              {data?.highlights.map((item) => (
                <li className="mb-2 d-flex gap-2">
                  <img
                    src={
                      data?.fee <= 10
                        ? data?.blueCheck?.default
                        : data?.greenCheck?.default
                    }
                    className="img-fluid subscript_checkers"
                  />
                  {item}
                </li>
              ))}
            </ul>
          </div>

          {/* <button
            className={`subsciption_green_gradBtn w-100 rounded-2 mt-4 ${
              data.price <= 10
                ? "subsciption_blue_gradBtn"
                : "subsciption_green_gradBtn"
            }`}>
            Learn more
          </button> */}
        </div>
      </div>
    </>
  );
}

export default SubscriptionCard;
