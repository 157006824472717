import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";
import { NavLink } from "react-router-dom";
import AddSubAdminModal from "../Modals/AddsubAdminModal";
import EditsubAdminModal from "../Modals/EditAdminModal";
import { getAdminList, EditAdminStatus } from "../actions/admin";
import toast from "react-hot-toast";

const Subadmin = () => {
  const [adminList, setAdminList] = useState();

  const getSubadminList = async () => {
    try {
      let { status, message, result } = await getAdminList();
      if (status == "success") {
        setAdminList(result);
      }
    } catch (err) {}
  };

  const changeAdminStatus = async (data) => {
    try {
      let { status, message, result } = await EditAdminStatus(data);
      if (status == "success") {
        toast.success("Status Changed Successfully");
        getSubadminList();
      } else {
        toast.error("Status Changed Failed");
        getSubadminList();
      }
    } catch (err) {}
  };

  useEffect(() => {
    getSubadminList();
  }, []);

  const state = [
    {
      user: <p className="exchange_table_values text-center">xyz</p>,
      roleName: <p className="exchange_table_values text-center">Admin</p>,
      roleAccess: <p className="exchange_table_values text-center">Accessed</p>,
    },
    {
      user: <p className="exchange_table_values text-center">xyz</p>,
      roleName: <p className="exchange_table_values text-center">Admin</p>,
      roleAccess: <p className="exchange_table_values text-center">Accessed</p>,
    },
    {
      user: <p className="exchange_table_values text-center">xyz</p>,
      roleName: <p className="exchange_table_values text-center">Admin</p>,
      roleAccess: <p className="exchange_table_values text-center">Accessed</p>,
    },
    {
      user: <p className="exchange_table_values text-center">xyz</p>,
      roleName: <p className="exchange_table_values text-center">Admin</p>,
      roleAccess: <p className="exchange_table_values text-center">Accessed</p>,
    },
  ];

  const columns = [
    {
      key: "name",
      text: "Name",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
    },
    {
      key: "email",
      text: "Email",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
    },
    {
      key: "role",
      text: "Role Name",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
    },
    {
      key: "restriction",
      text: "Role Access",
      className: "exchange_table_values text-center",
      align: "center",
      sortable: false,
    },

    {
      key: "action",
      text: "Action",
      className: "activity",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <>
            <div className="d-flex justify-content-center align-items-center gap-2">
              <button
                className="cmn_plain_btn"
                onClick={() => {
                  handleShowEditAdmin(record);
                }}>
                <img
                  src={require("../assets/images/editer.svg").default}
                  className="img-fluid table_activity_img"
                />{" "}
              </button>
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <Dropdown className="cmnStatus__changer">
                <Dropdown.Toggle id="dropdown-basic">
                  {record.status ? "Active" : "Deactive"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      changeAdminStatus({
                        _id: record._id,
                        status: true,
                      });
                    }}>
                    Activate
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      changeAdminStatus({
                        _id: record._id,
                        status: false,
                      });
                    }}>
                    Deactivate
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",
    no_data_text: "No Email Templates found!",
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: false,
    show_info: false,
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const [showAddAdmins, setshowAddAdmins] = useState(false);

  const handleShowAddAdmins = () => setshowAddAdmins(true);
  const handleCloseShowAddAdmins = () => setshowAddAdmins(false);

  // edid Exchange modal
  const [showEditAdmin, setShowEditAdmin] = useState(false);
  const [editRecord, setEditRecord] = useState();

  const handleShowEditAdmin = (record) => {
    setEditRecord(record);
    setShowEditAdmin(true);
  };

  const handleCloseEditAdmin = () => setShowEditAdmin(false);

  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"Subadmin"} />

            <div className="text-end mb-4">
              <button
                className="orange_small_primary"
                onClick={() => {
                  handleShowAddAdmins(true);
                }}>
                Add
              </button>
            </div>

            <div className="common_page_scroller pb-5 mt-3 mt-sm-5 pe-2">
              <div className="exchange_table_holder dashboard_box rounded-3 mt-4">
                <ReactDatatable
                  config={config}
                  records={adminList}
                  columns={columns}
                  extraButtons={extraButtons}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <AddSubAdminModal
        show={showAddAdmins}
        getSubadminList={getSubadminList}
        handleClose={handleCloseShowAddAdmins}
      />

      <EditsubAdminModal
        show={showEditAdmin}
        record={editRecord}
        getSubadminList={getSubadminList}
        handleClose={handleCloseEditAdmin}
      />
    </>
  );
};

export default Subadmin;
