import React, { useState } from "react";
import { Col, Row, Dropdown, Container, Offcanvas } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Connectwallet from "../Modals/Connectwallet";
import { useHistory } from "react-router-dom";
import { removeAuthToken } from "../lib/localStorage";
import nav from "../config/routes";
import { isEmpty } from "../lib/isEmpty";

function Header({ title }) {
  const history = useHistory();
  const logout = async () => {
    try {
      history.push("/");
      removeAuthToken();
    } catch (err) {
      console.log(err, "err_logout");
    }
  };

  let { restriction } = useSelector((state) => state.isRun);

  const dispatch = useDispatch();
  const switcher = useSelector((state) => state.switcher);
  const [mobSearch, setMobSearch] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // connect wallet modal

  const [showWallet, setShowWallet] = useState(false);

  const handleCloseWallet = () => setShowWallet(false);
  const handleShowWallet = () => setShowWallet(true);

  const setSwitcher = (val) => {
    dispatch({
      type: "switching",
      value: { switcher: val },
    });
  };

  return (
    <>
      <div className="header mt-2 px-2 position-relative">
        {/* {mobSearch && 
    <div className='mob_searchbar_holder p-3 d-flex justify-content-start align-items-center gap-3'>
                            <input type='text' placeholder='Search' className='mob_searchbar_inp flex-grow-1' />
                            <i class="fa-solid fa-xmark" onClick={() => setMobSearch(false)}  />
                    </div>} */}
        <Row className="align-items-center pt-2 pt-sm-3 h-100">
          <Col xl={6} lg={4} md={4} xs={6} className="d-flex gap-3">
            <p className="header_title d-block d-xl-none" onClick={handleShow}>
              <i class="fa-solid fa-bars" />
            </p>
            <p className="header_title">{title}</p>
          </Col>
          <Col
            xl={6}
            lg={8}
            md={8}
            xs={6}
            className="d-flex justify-content-end align-items-center gap-3">
            {/* <div className='header_searchbar_holder flex-grow-1 d-none d-md-block'>
        <div className='header_searchbar rounded-5 d-flex justify-content-start align-items-center gap-2 px-3 py-2'>
                        <img src={require('../assets/images/searchglass.svg').default} className='img-fluid' />
                        <input type='text' className='header_searchbar_input flex-grow-1' placeholder='Search' />
                    </div>
                    </div> */}
            {/* mobile searchbar */}
            {/* <img src={require('../assets/images/searchglass.svg').default} className='img-fluid mob_searchglass d-block d-md-none' onClick={() => setMobSearch(true)} /> */}

            {/* end of mobile searchbar */}
            {/* <div className='header_switch_holder d-none d-sm-block'>
                    <div className='header_dragger p-2 rounded-5 position-relative d-flex justify-content-center align-items-center w-100' >
                        <img src={switcher ? require('../assets/images/bitcoin.svg').default : require('../assets/images/thunder.svg').default} className={`img-fluid header_switchThunder ${switcher ? 'header_thunderRight' : 'header_thunderLeft'}`} onClick={()=> setSwitcher(!switcher)} />
                        <p className={`header_switchHint ${switcher ? 'me-3' : 'ms-3'} `}>{switcher ? 'Switch to BTC' : 'Switch to LN'}</p>
                    </div>
                    </div> */}

            <button
              className="orange_primary_btn header__cnctWalletBtn"
              onClick={handleShowWallet}>
              Connect Wallet
            </button>

            {/* <div className='header_profileImg_holder'>
                    <Dropdown className='header_profile_drop' drop='down'>
      <Dropdown.Toggle  id="dropdown-basic">
                    <img src={require('../assets/images/profile.png')} className='img-fluid header_profileImg rounded-5' />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item className='rounded-2' href='/profile'>Profile</Dropdown.Item>
        <Dropdown.Item className='rounded-2' href='/resetPassord'>Reset Password</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
                    </div> */}
          </Col>
        </Row>
      </div>

      {/* offcanva content */}
      <Offcanvas
        show={show}
        onHide={handleClose}
        backdrop="static"
        className="header_canvas">
        <Offcanvas.Body>
          <div className="header_canva_header pb-2 d-flex justify-content-between align-items-center">
            <img
              src={require("../assets/images/logo.svg").default}
              className="img-fluid"
            />
            <button
              className="cmn_modal_closer rounded-5"
              onClick={handleClose}>
              <i class="fa-solid fa-xmark" />
            </button>
          </div>
          <ul className="pt-4 sidebar__scrollUl">
            {nav && nav.length > 0
              ? nav.map((item) => {
                  // let Restriction = restriction
                  if (
                    restriction?.length > 0 &&
                    restriction?.includes(item.path)
                  ) {
                    return (
                      <li className="rounded-end-5 mb-3">
                        <NavLink
                          to={item.path}
                          className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                          <img
                            src={item.image}
                            className="img-fluid sidebar_linkImg"
                          />
                          <p className="sidebar_link_hint">{item.name}</p>
                        </NavLink>
                      </li>
                    );
                  } else if (isEmpty(restriction)) {
                    return (
                      <li className="rounded-end-5 mb-3">
                        <NavLink
                          to={item.path}
                          className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                          <img
                            src={item.image}
                            className="img-fluid sidebar_linkImg"
                          />
                          <p className="sidebar_link_hint">{item.name}</p>
                        </NavLink>
                      </li>
                    );
                  }
                })
              : ""}
            <li className="rounded-end-5 d-flex justify-content-start align-items-center gap-3 mb-3 p-2 ps-4" onClick={() => logout()}>
              {/* <NavLink className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4"> */}
              <img
                src={require("../assets/images/logout.svg").default}
                className="img-fluid sidebar_linkImg"
              />
              <p className="sidebar_link_hint">Logout</p>
              {/* </NavLink> */}
            </li>
          </ul>

          {/* <div className='header_switch_holder d-md-none ps-4'>
                    <div className='header_dragger p-2 rounded-5 position-relative d-flex justify-content-center align-items-center w-100' >
                        <img src={switcher ? require('../assets/images/bitcoin.svg').default : require('../assets/images/thunder.svg').default} className={`img-fluid header_switchThunder ${switcher ? 'header_thunderRight' : 'header_thunderLeft'}`} onClick={()=> {setSwitcher(!switcher);handleClose()}} />
                        <p className={`header_switchHint ${switcher ? 'me-3' : 'ms-3'} `}>{switcher ? 'Switch to BTC' : 'Switch to LN'}</p>
                    </div>
                    </div> */}
        </Offcanvas.Body>
      </Offcanvas>
      {/* end of offcanva content */}

      {/* modal */}
      <Connectwallet show={showWallet} handleClose={handleCloseWallet} />
      {/* end of modal */}
    </>
  );
}

export default Header;
