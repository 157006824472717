import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import { editSingleExchange } from "../actions/currency";
import { CheckFactory } from "../Hooks/useWeb3";
import toast from "react-hot-toast";

const EditExchangeModal = ({ show, handleClose, record, getAllExchanges }) => {
  const [formvalue, setFormvalue] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormvalue(record);
  }, [record]);

  const addressRegex = "^(0x)[0-9A-Fa-f]{40}$";
  const handleChange = async (e) => {
    try {
      const { name, value } = e.target;
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);
      if (name == "type") return;
      // if (name == "router") {
      //   var pattern = new RegExp(addressRegex);
      //   if (!pattern.test(value)) {
      //     return;
      //   }
      // }
      if (name == "router") {
        let fact = await CheckFactory(e.target.value, formvalue.chainId);
        // let Formvalue = { ...formvalue };
        if (fact.status == true) {
          Formvalue = {
            ...Formvalue,
            ["factory"]: fact.data,
          };
          setFormvalue(Formvalue);
          let error = { ...errors };
          error[`${name}`] = "";
          setErrors(error);
        } else {
          Formvalue = {
            ...Formvalue,
            ["factory"]: "",
          };
          setFormvalue(Formvalue);
          let error = { ...errors };
          error = { ...error, ["router"]: "Enter a valid router address" };
          setErrors(error);
        }
      }
      // setFormvalue(Formvalue);
      // let error = { ...errors };
      // error[`${name}`] = "";
      // setErrors(error);
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const validation = (value) => {
    try {
      let error = { ...errors };
      var pattern = new RegExp(addressRegex);

      if (isEmpty(value.name)) {
        console.log(isEmpty(value.name), "nameeeeee");
        error["name"] = "Field is required";
      } else {
        error["name"] = "";
      }
      if (isEmpty(value.factory)) {
        error["factory"] = "Field is required";
      } else {
        error["factory"] = "";
      }
      if (isEmpty(value.router)) {
        error["router"] = "Field is required";
      }
      // else if (isEmpty(value.router?.match(pattern))) {
      //   error["router"] = "Enter Valid Address";
      // }
      else {
        error["router"] = "";
      }
      if (isEmpty(value.chainId)) {
        error["chainId"] = "Field is required";
      } else {
        error["chainId"] = "";
      }
      // if (isEmpty(value.type)) {
      //   error["type"] = "Field is required";
      // } else {
      //   error["type"] = "";
      // }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      let exchangeData = {
        _id: record._id,
        name: formvalue.name,
        factory: formvalue.factory,
        router: formvalue.router,
        chainId: formvalue.chainId,
        type: formvalue.type,
      };
      let isValidate = validation(exchangeData);
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let { status, message, result } = await editSingleExchange(formvalue);

      if (status == "success") {
        toast.success("Exchange Updated Successfully");
        handleClose();
        getAllExchanges();
      } else {
        toast.error(message.email);
        handleClose();
        getAllExchanges();
      }
      handleClose();
      getAllExchanges();
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  const HandleClose = () => {
    handleClose();
    setFormvalue({});
    setErrors({});
  };

  return (
    <>
      <Modal
        centered
        className="cmn_modal"
        show={show}
        onHide={HandleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div className="cmn_modal_header d-flex justify-content-between align-items-center">
            <p className="cmn_modal_title">Edit Exchange</p>
            <button
              className="cmn_modal_closer rounded-5"
              onClick={() => {
                HandleClose();
              }}>
              <i class="fa-solid fa-xmark" />
            </button>
          </div>

          <div className="mt-4">
            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Name</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={formvalue?.name}
                  className="rp_singleInput flex-grow-1"
                  onChange={handleChange}
                />
                <span className="text-danger">{errors && errors.name}</span>
              </div>
            </div>
            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Router</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Name"
                  name="router"
                  readOnly
                  value={formvalue?.router}
                  className="rp_singleInput flex-grow-1"
                  onChange={handleChange}
                />
              </div>
              <span className="text-danger">{errors && errors.router}</span>
            </div>{" "}
            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Factory</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Name"
                  name="factory"
                  readOnly
                  value={formvalue?.factory}
                  className="rp_singleInput flex-grow-1"
                  onChange={handleChange}
                />
              </div>
              <span className="text-danger">{errors && errors.factory}</span>
            </div>{" "}
            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Chain id</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Name"
                  name="chainId"
                  readOnly
                  value={formvalue?.chainId}
                  className="rp_singleInput flex-grow-1"
                  onChange={handleChange}
                />
                <span className="text-danger">{errors && errors.chainId}</span>
              </div>
            </div>
            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Type</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Name"
                  name="type"
                  readOnly
                  value={formvalue?.type}
                  className="rp_singleInput flex-grow-1"
                  onChange={handleChange}
                />
                <span>{errors && errors.type}</span>
              </div>
            </div>
            <button
              className="orange_small_primary mt-3"
              onClick={() => {
                handleSubmit();
              }}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditExchangeModal;
