import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import { editSingleToken } from "../actions/currency";
import toast from "react-hot-toast";

const EditTokenModal = ({ show, handleClose, record, getTokens }) => {
  const [formvalue, setFormvalue] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormvalue(record);
  }, [record]);

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);
      setErrors(validation(Formvalue));
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const validation = (value) => {
    try {
      let error = errors;
      if (isEmpty(value.name)) {
        error["name"] = "Field is required";
      } else {
        error["name"] = "";
      }
      if (isEmpty(value.address)) {
        error["address"] = "Field is required";
      } else {
        error["address"] = "";
      }
      if (isEmpty(value.symbol)) {
        error["symbol"] = "Field is required";
      } else {
        error["symbol"] = "";
      }
      if (isEmpty(value.decimals)) {
        error["decimals"] = "Field is required";
      } else {
        error["decimals"] = "";
      }
      if (isEmpty(value.chainId)) {
        error["chainId"] = "Field is required";
      } else {
        error["chainId"] = "";
      }
      if (isEmpty(value.logoURI)) {
        error["logoURI"] = "Field is required";
      } else {
        error["logoURI"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      let tokenData = {
        _id: record._id,
        chainId: formvalue.chainId,
        address: formvalue.address,
        symbol: formvalue.symbol,
        name: formvalue.name,
        decimals: formvalue.decimals,
        logoURI: formvalue.logoURI,
      };

      let isValidate = validation(tokenData);
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let { status, message, result } = await editSingleToken(formvalue);
      if (status == "success") {
        toast.success("Token Updated Successfully");
        handleClose();
        getTokens();
      } else {
        toast.error("Token Updated Failed");
        handleClose();
        getTokens();
      }
      handleClose();
      getTokens();
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  return (
    <>
      <Modal
        centered
        className="cmn_modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div className="cmn_modal_header d-flex justify-content-between align-items-center">
            <p className="cmn_modal_title">Edit Pairs</p>
            <button
              className="cmn_modal_closer rounded-5"
              onClick={handleClose}>
              <i class="fa-solid fa-xmark" />
            </button>
          </div>

          <div className="mt-4">
            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Name</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={formvalue?.name}
                  className="rp_singleInput flex-grow-1"
                  onChange={handleChange}
                />
                <span>{errors && errors.name}</span>
              </div>
            </div>

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Address</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="address"
                  name="address"
                  readOnly
                  className="rp_singleInput flex-grow-1"
                  value={formvalue?.address}
                  onChange={handleChange}
                />
                <span>{errors && errors.address}</span>
              </div>
            </div>

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Symbol</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="symbol"
                  name="symbol"
                  className="rp_singleInput flex-grow-1"
                  value={formvalue?.symbol}
                  onChange={handleChange}
                />
                <span>{errors && errors.symbol}</span>
              </div>
            </div>

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Decimals</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Enter Token Decimals"
                  name="decimals"
                  readOnly
                  className="rp_singleInput flex-grow-1"
                  value={formvalue?.decimals}
                  onChange={handleChange}
                />
                <span>{errors && errors.decimals}</span>
              </div>
            </div>

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Chain Id</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  name="chainId"
                  readOnly
                  placeholder="Enter Token Address"
                  className="rp_singleInput flex-grow-1"
                  value={formvalue.chainId}
                  onChange={handleChange}
                />
                <span>{errors && errors.chainId}</span>
              </div>
            </div>

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Logo URI</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  name="logoURI"
                  placeholder="Enter Token Address"
                  className="rp_singleInput flex-grow-1"
                  value={formvalue?.logoURI}
                  onChange={handleChange}
                />
                <span>{errors && errors.logoURI}</span>
              </div>
            </div>

            <button
              className="orange_small_primary mt-3"
              onClick={() => {
                handleSubmit();
              }}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditTokenModal;
