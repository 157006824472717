// import action
import { decodeJwt } from "./jsonWebToken";

// import config
import axios, { setAuthorization } from "../config/axios";

export const addNewPair = async (data) => {
  console.log(data, "datadata");
  try {
    let respData = await axios({
      method: "post",
      url: `/currencyapi/add-pair`,
      data,
    });
    return {
      status: respData.data.status ? "success" : "Failed",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getPairs = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/currencyapi/get-all-pair`,
    });
    console.log(respData, "respData___getPairs");
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
      count: respData.data.count,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getSchedules = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/market/get-schedules`,
    });
    console.log(respData, "respData___getPairs");
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getPair = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/currencyapi/get-pair`,
      data: data,
    });
    console.log(respData, "respData");
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const editSinglePair = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/currencyapi/edit-pair`,
      data: data,
    });
    console.log(respData, "respData");
    return {
      status: respData.data.status ? "success" : "Failed",
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const deleteSinglePair = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/currencyapi/delete-pair`,
      data: data,
    });
    console.log(respData, "respData");
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const deleteSingleSchedules = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/market/delete-schedules`,
      data: data,
    });
    console.log(respData, "respData");
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const addNewExchange = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/currencyapi/add-exchange`,
      data: data,
    });
    console.log(respData, "respData");
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getExchanges = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/currencyapi/get-all-exchange`,
      data: data,
    });
    console.log(respData, "respData");
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
      count: respData.data.count,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const editSingleExchange = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/currencyapi/edit-exchange`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.errors,
      error: err.response.data.errors,
    };
  }
};

export const deleteSingleExchange = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/currencyapi/delete-exchange`,
      data: data,
    });
    console.log(respData, "respData");
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const addNewPlan = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/currencyapi/add-subscription-plan`,
      data: data,
    });
    console.log(respData, "respData");
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getAllPlans = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/currencyapi/get-all-subscription-plan`,
      data: data,
    });
    console.log(respData, "respData");
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const editPlan = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/currencyapi/edit-subscription-plan`,
      data: data,
    });
    console.log(respData, "respData");
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const deletePlan = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/currencyapi/delete-subscription-plan`,
      data: data,
    });
    console.log(respData, "respData");
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const addNewToken = async (data) => {
  console.log(data, "datadata");
  try {
    let respData = await axios({
      method: "post",
      url: `/currencyapi/add-currency`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getAllTokens = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/currencyapi/get-all-currency`,
      data,
    });
    console.log(respData, "respData");
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
      count: respData.data.count,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getSingleToken = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/currencyapi/get-currency`,
      data: data,
    });
    console.log(respData, "respData");
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const editSingleToken = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/currencyapi/edit-currency`,
      data: data,
    });
    console.log(respData, "respData");
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const deleteSingleToken = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/currencyapi/delete-currency`,
      data: data,
    });
    console.log(respData, "respData");
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
