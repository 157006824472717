import Web3 from "web3";
import { RPC_LISTS } from "../config/env";
import { ERC20_ABI } from "../config/erc20";
import { ROUTER_ABI } from "../config/Router";
import { ethers } from "ethers";

let defaultProvider = null;
let defaultWeb3 = null;
export const UseWeb3 = (error = false, chainId) => {
  console.log(chainId,"chainIdchainId",!error && defaultWeb3)
  if (!error && defaultWeb3) return defaultWeb3;
  console.log(RPC_LISTS[chainId], "RPC_LISTS[chainId][0]");
  const httpProvider = new Web3.providers.HttpProvider(RPC_LISTS[chainId][0]);
  const web3 = new Web3(httpProvider);
  // defaultWeb3 = web3;
  return web3;
};

export const UseERC20 = async (Token, chainId) => {
  const web3 = await UseWeb3(false, chainId);
  const contract = new web3.eth.Contract(ERC20_ABI, Token);
  return contract;
};

export const UseToken = async (chainId, address) => {
  try {
    let details = {};
    const web3 = await UseWeb3(false, chainId);
    const checkIsValid = web3.utils.isAddress(address);
    if (checkIsValid) {
      const token = await UseERC20(address, chainId);
      details.tokenName = await token.methods.name().call();
      details.tokenSymbol = await token.methods.symbol().call();
      details.tokenDecimal = await token.methods.decimals().call();
      console.log("useToken_tokenDetails_data", details);
      return {
        status: true,
        data: details,
      };
    } else {
      return {
        status: false,
        data: {},
      };
    }
  } catch (e) {
    console.log("useToken_err", e);
    return {
      status: false,
      data: {},
    };
  }
};

export const CheckFactory = async (router, chainId) => {
  console.log("🚀 ~ CheckFactory ~ router, chainId:", router, chainId);
  try {
    // const web3 = await UseWeb3(false, chainId);
    const provider = new ethers.JsonRpcProvider(RPC_LISTS[chainId][0]);
    const router0 = new ethers.Contract(router, ROUTER_ABI, provider);
    // const router = new web3.eth.Contract(router, ROUTER_ABI);
    const factory0address = await router0.factory();
    console.log("🚀 ~ CheckFactory ~ factory0address:", factory0address);
    return {
      status: true,
      data: factory0address,
    };
  } catch (err) {
    console.log("🚀 ~ CheckFactory ~ err:", err);
    return {
      status: false,
      data: {},
    };
  }
};
